import GlobalLayout from "components/page/GlobalLayout";
import PricingPage from "components/pricing/PricingPage";
import React from "react";
import Helmet from "react-helmet";

export default function SensorPricing() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus List Pricing - Enterprise Environmental Sensors</title>
        <meta
          name="description"
          content="Smart environmental sensors for commercial businesses and enterprise. View Rhombus list pricing and request a custom quote."
        />
      </Helmet>
      <PricingPage product="sensors" />
    </GlobalLayout>
  );
}
